import { getDatabase, ref, set } from "firebase/database";
import { getAuth } from "firebase/auth";

const write = (path, data) => {
	return new Promise(resolve => {
		const user = getAuth().currentUser;
		if (user && user.uid === "KsunAtlcNiPgDIKBzTkUrfskR993" && user.providerData[0].providerId === 'google.com') {
			const db = getDatabase();
			set(ref(db, path), data).then(() => resolve('completed'));
		} else {
			resolve('You do not have sufficient permissions to perform this action');
		}
	});
}

export default write;