import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import signOutUser from '../../firebase/signOutUser';
import classes from './NavMenu.module.css';
import NavMenuButton from './NavMenuButton';

export default function NavMenu({ Mobile, OnChoice }) {
	const location = useLocation();
	const navigate = useNavigate();

	const CloseNavigate = url => {
		if (Mobile) {
			OnChoice();
			navigate(url);
		} else {
			navigate(url);
		}
	}

	return (
		<div className={classes.navMenuOuter}>
			<div>
				<span className={classes.siteTitle}>Blog Admin Console</span>

				<div className={classes.navButtonContainer}>
					<NavMenuButton selected={location.pathname === '/'} Click={() => CloseNavigate('/')}>Home</NavMenuButton>
					<NavMenuButton selected={location.pathname === '/posts'} Click={() => CloseNavigate('/posts')}>Posts</NavMenuButton>
					<NavMenuButton selected={location.pathname === '/newpost'} Click={() => CloseNavigate('/newpost')}>New Post</NavMenuButton>
					<NavMenuButton selected={location.pathname === '/campaigns'} Click={() => CloseNavigate('/campaigns')}>Campaigns</NavMenuButton>
					<NavMenuButton selected={location.pathname === '/categories'} Click={() => CloseNavigate('/categories')}>Category Manager</NavMenuButton>
					<NavMenuButton selected={location.pathname === '/settings'} Click={() => CloseNavigate('/settings')}>Settings</NavMenuButton>
					<NavMenuButton Click={() => window.open('https://sitemap.sebdoe.com')}>Sitemap</NavMenuButton>
				</div>
			</div>
			<div>
				<NavMenuButton Click={() => signOutUser()}>Sign Out</NavMenuButton>
			</div>
		</div>
	)
}