import React, { useEffect, useState } from 'react';
import { Button, ThemeProvider, createTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChromePicker } from 'react-color';
import { motion } from 'framer-motion';

import PageTitle from '../components/ui/PageTitle';
import fetch from '../firebase/fetch';
import write from '../firebase/write';
import deleteElement from '../firebase/deleteElement';

export default function Category() {
	const location = useLocation();
	const key = location.pathname.split('/').pop();

	let navigate = useNavigate();

	const [categoryInfo, setCategoryInfo] = useState('Loading...');
	const [colour, setColour] = useState('#f1f1f1');

	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Category', 'page_location': window.location.href, 'page_path': '/categories'});

		fetch(`/blog/categories/${key}/info`).then(categoryFetched => {
			setCategoryInfo(categoryFetched);
			setColour(`#${categoryFetched.color}`);
		});
	}, []);

	const theme = createTheme({
		palette: {
			mode: 'dark',
		},
		components: {
			MuiButton: {
				textTransform: "none",
				fontFamily: `"Inter", sans-serif`,
				fontWeight: 600,
				textAlign: "left",
				justifyContent: "flex-start",
				padding: "1rem 1.5rem",
				borderRadius: "1rem",
			}
		},
	});
	
	const revert = () => setColour(categoryInfo.color);

	const save = () => {
		write(`/blog/categories/${key}/info/color`, colour.slice(1)).then(() => {
			fetch(`/blog/categories/${key}/articles/page`).then(categoryArticles => {
				categoryArticles.forEach(categoryPage => {
					categoryPage.forEach(categoryArticle => {
						const categoryId = categoryArticle.split('|||')[0].toLowerCase().split(' ').join('-');
						fetch(`/blog/posts/${categoryId}`).then(postPageInfo => {
							const postPage = postPageInfo['pg'].split(':');
							const pageNumber = postPage[0];
							const pageIndex = postPage[1];
							fetch(`/blog/recent/page/${pageNumber}/${pageIndex}`).then(recentPostInfo => {
								recentPostInfo = recentPostInfo.split('|||');
								recentPostInfo[3] = colour.slice(1);
								recentPostInfo = recentPostInfo.join('|||');
								write(`/blog/recent/page/${pageNumber}/${pageIndex}`, recentPostInfo);
							});
							write(`/blog/posts/${categoryId}/category`, `${categoryInfo.name}${colour.slice(1)}`)
							
						});
					});
				});
			});
		});
	}

	const remove = () => deleteElement(`/blog/categories/${categoryInfo.name.toLowerCase().split(' ').join('-')}`).then(() => navigate('/categories'));

	return (
		<motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
			<PageTitle Colour={categoryInfo !== 'Loading...' && colour}>{ categoryInfo === 'Loading...' ? 'Category' : categoryInfo.name }</PageTitle>

			<ChromePicker
				onChange={ color => setColour(color.hex) }
				color={colour}
			/>

			<Button style={{ display: 'inline-block', marginRight: '3rem', marginTop: "1.5rem" }}>
				<span style={{
					color: colour,
					fontWeight: 600,
					display: "inline-block",
					textTransform: "none",
					fontSize: "1rem",
					fontFamily: "Inter",
				}}>{categoryInfo.name}</span>
			</Button>

			{ categoryInfo === 'Loading...' ? <span>Loading...</span> :
				<ThemeProvider theme={theme}>
					<div style={{ marginTop: "3rem", textAlign: "right" }}>
						{ `#${categoryInfo.color}` !== colour && <Button onClick={revert}>Revert Colour</Button> }
						<Button onClick={save} variant="contained" style={{ marginLeft: '1.5rem' }}>Save</Button>
						{ categoryInfo.hasArticles === 0 && <Button onClick={remove} variant="contained" style={{ color: 'white', backgroundColor: 'red', marginLeft: '1.5rem' }}>Delete Category</Button> }
					</div>
				</ThemeProvider>
			}
		</motion.div>
	)
}