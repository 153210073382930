import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import PageTitle from '../components/ui/PageTitle';

export default function Settings() {
	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Settings', 'page_location': window.location.href, 'page_path': '/settings'});
	}, []);

	return (
		<motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
			<PageTitle>Help</PageTitle>
			<p>Content that should be displayed as a campaign should be shown in the following format:</p>
			<code>[out|campaignID|Description]</code>
			<br /><br />
			<p>Links:</p>
			<code>[Description](url)</code>
			<br /><br />
			<p>Information (must be placed as an inline paragraph; the title or description may be omitted if only the other is required):</p>
			<code>Title|||Description</code>
		</motion.div>
	)
}