import React from 'react';
import { Helmet } from 'react-helmet';

export default function Header({ Title }) {
	return (
		<Helmet>
			<title>{`${Title} | Blog Admin Console`}</title>
			<meta name="robots" content="noindex, nofollow" />
			<meta name="googlebot" content="noindex, nofollow" />
			<meta name="googlebot-news" content="noindex, nofollow" />
		</Helmet>
	)
}