import React, { useState } from 'react';
import { createTheme, ThemeProvider, SwipeableDrawer, IconButton, Button } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import classes from './Layout.module.css';
import NavMenu from './NavMenu';

export default function Layout({ children, isMobile }) {
	let navigate = useNavigate();

	const [navBarOpen, setNavBarOpen] = useState(false);

	const handleScroll = e => {
		if (window.location.pathname === '/posts') {
			const el = document.getElementById('end');
			var rect = el.getBoundingClientRect();
			var elemTop = rect.top;
			var elemBottom = rect.bottom;
			const bottom = (elemTop >= 0) && (elemBottom <= window.innerHeight);
			if (bottom) window['bottomReached']();
		}
	}

	const theme = createTheme({
		palette: {
			mode: "dark",
			primary: {
				main: "#cccccc",
			}
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						display: "inline-block",
						marginLeft: "1.25rem",
						fontSize: "1.15rem",
						fontFamily: `"Inter", sans-serif`,
						fontWeight: 500,
						textTransform: "none"
					},
				},
			},
		},
	});

	if (isMobile) {
		return (
			<ThemeProvider theme={theme}>
				<SwipeableDrawer
					anchor="left"
					open={navBarOpen}
					onClose={() => setNavBarOpen(false)}
					onOpen={() => setNavBarOpen(true)}
				>
					<div className={classes.mobileNavMenuHolder}>
						<NavMenu Mobile OnChoice={() => setNavBarOpen(false)} />
					</div>
				</SwipeableDrawer>
				<header className={classes.mobileHeader}>
					<div className={classes.mobileHeaderLeft}>
						<IconButton size="medium" onClick={() => setNavBarOpen(true)} aria-label="open menu">
							<Menu fontSize="medium" style={{ color: '#cccccc' }} />
						</IconButton>
						<Button onClick={() => navigate('/')} aria-label="home">
							<div className={classes.mobileHeaderButtonFlex}>
								Blog Admin Console
							</div>
						</Button>
					</div>
				</header>
				<div className={classes.mobileContentOuter} onScroll={handleScroll} onTouchMove={handleScroll}>
					<div className={classes.mobileContent}>
						{children}
					</div>
				</div>
			</ThemeProvider>
		)
	} else {
		return (
			<main className={classes.allContainer} onScroll={handleScroll}>
				<table className={classes.tableContainer}>
					<tr>
						<th className={classes.leftColumn}>
							<NavMenu />
						</th>
						<th className={classes.mainContent}>
							{children}
						</th>
					</tr>
				</table>
			</main>
		)
	}
}