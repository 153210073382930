import React from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';

import classes from './CampaignDisplay.module.css';

export default function CampaignDisplay({ SiteURL, SiteName, Description, Type }) {
	const theme = createTheme({
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						width: "100%",
						borderRadius: "1.5rem",
						padding: "2rem 2.5rem",
						border: "none",
						transition: "0.1s ease-in-out",
						cursor: "pointer",
						justifyContent: "space-between",
						marginBottom: "0.5rem",
						textTransform: "none",
						fontFamily: "Inter, sans-serif",
						backgroundColor: "#000000",
						whiteSpace: "pre-line",
					}
				}
			}
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Button onClick={() => window.open(SiteURL)}>
				<div className={classes.campaignContentOuter}>
					<span className={classes.campaignType}>{ Type === 'referral' ? 'Referral' : 'Affiliate Link' }</span>
					<br />
					<span className={classes.campaignSiteName}>{SiteName}</span>
					<br />
					<span className={classes.campaignDescription}>{Description}</span>
				</div>
			</Button>
		</ThemeProvider>
	)
}