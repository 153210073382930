import { createTheme, ThemeProvider, Button } from '@mui/material';
import React from 'react';

import classes from './CategoryButton.module.css';

export default function CategoryButton({ Colour, Name, Click }) {
	const theme = createTheme({
		palette: {
			mode: 'dark',
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						width: "100%",
						borderRadius: "0.4rem",
						padding: "1rem 1.5rem",
						border: "none",
						transition: "0.1s ease-in-out",
						cursor: "pointer",
						color: "rgba(255, 255, 255, 0.8)",
						justifyContent: "space-between",
						marginBottom: "0.5rem",
						textTransform: "capitalize",
						fontFamily: "Inter, sans-serif",
						fontWeight: 600,
						fontSize: "1rem",
						backgroundColor: "rgba(255, 255, 255, 0.02)",
						display: "inline-block",
						textAlign: "left",
					},
				},
			},
		},
	});

  	return (
		<ThemeProvider theme={theme}>
			<Button onClick={Click}>
				<span className={classes.categoryText} style={{ color: Colour }}>{Name}</span>
			</Button>
		</ThemeProvider>
	);
}