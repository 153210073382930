import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";

const fetch = path => {
	return new Promise(resolve => {
		const user = getAuth().currentUser;
		if (user && user.uid === "KsunAtlcNiPgDIKBzTkUrfskR993" && user.providerData[0].providerId === 'google.com') {
			const db = getDatabase();
			onValue(ref(db, path), snapshot => resolve(snapshot.val()));
		} else {
			resolve('You do not have sufficient permissions to access this information');
		}
	});
}

export default fetch;