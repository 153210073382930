import React, { useEffect, useState } from 'react';
import { ThemeProvider, ToggleButton, ToggleButtonGroup, createTheme, TextField, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import classes from './PostEditor.module.css';
import fetch from '../../firebase/fetch';
import write from '../../firebase/write';
import deleteElement from '../../firebase/deleteElement';
import getLastChild from '../../firebase/getLastChild';
import MarkdownDisplay from './MarkdownDisplay';
import DialogElement from './DialogElement';
import PingRequest from '../../scripts/PingRequest';

export default function PostEditor({ providedDate, PostInfo, isMobile }) {
	let navigate = useNavigate();

	const [editorState, setEditorState] = useState('edit');
	const [storyName, setStoryName] = useState(PostInfo ? PostInfo['name'] : window['storyName']);
	const [category, setCategory] = useState(PostInfo ? PostInfo['category'].slice(0, -6) : window['category'] === undefined ? '' : window['category']);
	const [date, setDate] = useState(PostInfo ? new Date(Number(`${PostInfo['date']}000`)) : providedDate);
	const [storyContent, setStoryContent] = useState(PostInfo ? PostInfo['content'] : window['storyText']);
	const [categoryList, setCategoryList] = useState([]);
	const [open, setOpen] = useState(false);
	const [socialPromptOpen, setSocialPromptOpen] = useState(false);
	const [statusId, setStatusId] = useState(PostInfo ? (PostInfo.twitter ? PostInfo.twitter : '') : '');


	useEffect(() => {
		fetch('/blog/categories').then(res => {
			setCategoryList(res);
		});
	}, []);

	const saveDraft = () => {
		localStorage['draft'] = JSON.stringify({
			"storyName": storyName,
			"category": category,
			"date": date,
			"storyContent": storyContent,
			"exists": true
		});
	}

	const loadDraft = () => {
		const draftContent = JSON.parse(localStorage['draft']);
		if (draftContent["exists"]) {
			setStoryName(draftContent["storyName"]);
			setCategory(draftContent["category"]);
			setDate(draftContent["date"]);
			setStoryContent(draftContent["storyContent"]);
		}
	}

	const publishStory = () => {
		if (category) {
			fetch(`/blog/posts/${storyName.toLowerCase().split(' ').join('-')}`).then(storyExists => {
				if (storyExists == null) {
					const dateString = new Date(date).getTime().toString().slice(0, -3);

					write(`/blog/posts/${storyName.toLowerCase().split(' ').join('-')}`, {
						date: dateString,
						category: category + categoryList[category.toLowerCase()].info.color,
						content: storyContent,
						name: storyName
					});

					const submitCategories = () => {
						return new Promise(resolve => {
							getLastChild(`/blog/categories/${category.toLowerCase()}/articles/page`).then(res => {
								if (res === null) {
									write(`/blog/categories/${category.toLowerCase()}/articles/page/1`, ['']).then(() => {
										submitCategories().then(() => resolve('1:0'));
									});
								} else {
									const pageNum = Object.keys(res)[0];
									const numElements = res[pageNum].length;
				
									if (res[pageNum].at(-1) === '') res[pageNum].pop();
						
									if (numElements < 10) {
										res[pageNum].push(`${storyName}|||${dateString}`);
										write(`/blog/categories/${category.toLowerCase()}/articles/page/${pageNum}`, res[pageNum]).then(() => resolve(`${pageNum}:${numElements}`));
									} else {
										write(`/blog/categories/${category.toLowerCase()}/articles/page/${(Number(pageNum) + 1).toString()}`, [`${storyName}|||${dateString}`]).then(() => resolve(`${Number(pageNum) + 1}:0`));
									}
								}
							});
						});
					}

					getLastChild(`/blog/recent/page`).then(res => {
						const pageNum = Object.keys(res)[0];
						const numElements = res[pageNum].length;

						let pageNumString;

						// res[pageNum] is the array of stories for the current page

						// Prevents the pages from being over 10 items long
						if (numElements < 10) {
							res[pageNum].push(`${storyName}|||${dateString}|||${category}|||${categoryList[category.toLowerCase()].info.color}`);
							pageNumString = `${pageNum}:${numElements}`;
							write(`/blog/recent/page/${pageNum}`, res[pageNum]);
						} else {
							pageNumString = `${Number(pageNum) + 1}:0`;
							write(`/blog/recent/page/${(Number(pageNum) + 1).toString()}`, [`${storyName}|||${dateString}|||${category}|||${categoryList[category.toLowerCase()].info.color}`]);
						}

						submitCategories().then(categoryPageNum => {
							write(`/blog/posts/${storyName.toLowerCase().split(' ').join('-')}/cat`, categoryPageNum);
							write(`/blog/posts/${storyName.toLowerCase().split(' ').join('-')}/pg`, pageNumString);
							fetch(`/blog/categories/${category.toLowerCase().split(' ').join('-')}/info/hasArticles`).then(numArticles => {
								write(`/blog/categories/${category.toLowerCase().split(' ').join('-')}/info/hasArticles`, numArticles += 1);
								PingRequest().then(() => {
									const url = `https://twitter.com/intent/tweet?url=https%3A%2F%2Fsebdoe.com%2Farticle%2F${encodeURIComponent(storyName.toLowerCase().split(' ').join('-'))}&text=${encodeURIComponent(storyName)}%20%7C%20${encodeURIComponent(category)}%20%7C%20Sebastian%20Doe&hashtags=coding%2Cjavascript%2Cdev%2C${encodeURIComponent(category.toLowerCase().split(' ').join('_').split('-').join('_'))}`;
									window.open(url);
									window.open('https://newsitemap.sebdoe.com/newsitemapplease');
									console.log(url);
									setSocialPromptOpen(true);
								});
							});
						});
					});
				} else {
					alert('Story name already exists');
				}
			});
		} else {
			alert('No category selected');
		}
	}

	const certainDelete = () => {
		setOpen(true);
	}

	const deletePost = () => {
		setOpen(false);
		
		const storyId = storyName.toLowerCase().split(' ').join('-');

		fetch(`/blog/posts/${storyId}/cat`).then(categoryArray => {
			categoryArray = categoryArray.split(':');
			// category page number is [0], index is [1]

			fetch(`/blog/posts/${storyId}/pg`).then(pageArray => {
				pageArray = pageArray.split(':');
				// recent page number is [0], index is [1]

				const categoryId = PostInfo['category'].slice(0, -6).toLowerCase().split(' ').join('-');
	
				// Ensure that all delete processes have occurred before redirecting
				let doneList = [false, false, false];
				const done = doneNum => {
					doneList[doneNum] = true;

					if (doneList[0] === true && doneList[1] === true && doneList[2] === true) {
						fetch(`/blog/categories/${categoryId}/info/hasArticles`).then(numArticles => {
							write(`/blog/categories/${categoryId}/info/hasArticles`, numArticles -= 1);
						});
						window.open('https://newsitemap.sebdoe.com/newsitemapplease');
						navigate('/posts');
					};
				}

				deleteElement(`/blog/posts/${storyId}`).then(() => done(0));
				deleteElement(`/blog/recent/page/${pageArray[0]}/${pageArray[1]}`).then(() => done(1));
				deleteElement(`/blog/categories/${categoryId}/articles/page/${categoryArray[0]}/${categoryArray[1]}`).then(() => done(2));
			});
		});
	}

	const updateStory = () => {
		const storyId = storyName.toLowerCase().split(' ').join('-');
		
		write(`/blog/posts/${storyId}/content`, storyContent).then(() => {
			window.open(`https://sebdoe.com/article/${storyId}`);
			navigate('/posts');
		});
	}

	const darkTheme = createTheme({
		palette: {
			mode: 'dark'
		},
		typography: {
			fontFamily: `"Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
		},
	});

	const formatting = () => {
		window.open('/help', 'Formatting Help', 'width=1000,height=580');
	}

	const sharePost = () => {
		const url = `https://twitter.com/intent/tweet?url=https%3A%2F%2Fsebdoe.com%2Farticle%2F${encodeURIComponent(storyName.toLowerCase().split(' ').join('-'))}&text=${encodeURIComponent(storyName)}%20%7C%20${encodeURIComponent(category)}%20%7C%20Sebastian%20Doe&hashtags=coding%2Cjavascript%2Cdev%2C${encodeURIComponent(category.toLowerCase().split(' ').join('_').split('-').join('_'))}`;
		window.open(url);
		console.log(url);
		setSocialPromptOpen(true);
	}

	const closeSocialDialog = state => {
		setSocialPromptOpen(false);
		if (state) {
			write(`/blog/posts/${storyName.toLowerCase().split(' ').join('-')}/twitter`, statusId).then(() => {
				if (!PostInfo) {
					navigate('/posts');
					window.open(`https://sebdoe.com/article/${storyName.toLowerCase().split(' ').join('-')}`);
				} else {
					navigate(window.location.pathname);
				}
			});
		} else {
			if (!PostInfo) {
				navigate('/posts');
				window.open(`https://sebdoe.com/article/${storyName.toLowerCase().split(' ').join('-')}`);
			} else {
				navigate(window.location.pathname);
			}
		}
	}

	return (
		<ThemeProvider theme={darkTheme}>
			<DialogElement open={open} onClose={() => setOpen(false)} onDelete={deletePost} />

			<Dialog open={socialPromptOpen} onClose={() => closeSocialDialog(false)} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', backdropFilter: 'blur(5px)' }} component={motion.div} initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
				<div style={{ padding: '2rem', backgroundColor: '#141313', textAlign: 'center' }}>
					<DialogTitle>Submit Status ID</DialogTitle>
					<DialogContent>
					<DialogContentText>
						Submit the post's Status ID so that a comments link can be displayed.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Status ID"
						type="text"
						variant="outlined"
						fullWidth
						style={{ marginTop: '2rem', marginBottom: '1rem' }}
						value={statusId}
						onChange={newStatusId => {
							setStatusId(newStatusId.target.value);
						}}
					/>
					</DialogContent>

					<Button variant="outlined" color="secondary" style={{ marginRight: '0.5rem' }} onClick={() => closeSocialDialog(false)}>Cancel</Button>
					<Button variant="outlined" color="primary" onClick={() => closeSocialDialog(true)}>Submit</Button>
				</div>
			</Dialog>

			<Stack direction="column" spacing={2} sx={{ maxWidth: '100%' }}>
				<TextField
					id="storyName"
					label="Story Name"
					variant="outlined"
					value={storyName}
					autoFocus
					onChange={newStoryName => {
						window['storyName'] = newStoryName.target.value;
						setStoryName(newStoryName.target.value);
					}}
					style={{ minWidth: isMobile ? 0 : 500 }}
					disabled={!!PostInfo}
					fullWidth={true}
				/>

				<Stack direction="row" spacing={2}>
					<FormControl style={{ width: isMobile ? '100%' : '50%' }}>
						<InputLabel id="categorySelectLabel">Category</InputLabel>
						<Select
							labelId="categorySelectLabel"
							value={category}
							label="Category"
							onChange={(event, newCategory) => {
								window['category'] = newCategory.props.value;
								setCategory(newCategory.props.value);
							}}
							disabled={!!PostInfo}
							fullWidth={true}
						>
							{
								categoryList === [] ?
								<MenuItem value="Programming">Programming</MenuItem>
								:
								Object.keys(categoryList).map(categoryKey => {
									const categoryDisplayName = categoryList[categoryKey].info.name;
									return <MenuItem value={categoryDisplayName} key={categoryDisplayName}>{categoryDisplayName}</MenuItem>
								})
							}
						</Select>
					</FormControl>

					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label="Publish Date"
							value={date}
							onChange={newDate => setDate(newDate)}
							renderInput={params => <TextField {...params} sx={{ width: isMobile ? '100%' : '50%' }} />}
							disabled={!!PostInfo}
						/>
					</LocalizationProvider>
				</Stack>
			</Stack>
			
			<br /><br /><br />

			<div className={classes.fullWidth}>
				<div className={classes.rightFloat}>
					<ToggleButtonGroup
						size="medium"
						color="primary"
						value={editorState}
						exclusive
						orientation="horizontal"
						onChange={(event, newAlignment) => {
							if (newAlignment != null) setEditorState(newAlignment);
							// Prevents value from becoming null
							else setEditorState(editorState === 'edit' ? 'edit' : 'preview');
						}}
					>
						<ToggleButton value="edit">Edit</ToggleButton>
						<ToggleButton value="preview">Preview</ToggleButton>
					</ToggleButtonGroup>
				</div>
			</div>
			
			<br />
			
			{ editorState === 'edit' ?
				<TextField
					id="storyContent"
					label="Content"
					multiline
					rows={16}
					value={storyContent}
					onChange={newStoryName => {
						window['storyText'] = newStoryName.target.value;
						setStoryContent(newStoryName.target.value);
					}}
					inputProps={{
						style: {
							fontFamily: `"Roboto Mono", "Helvetica", "Arial", sans-serif`,
							height: 300,
						},
					}}
					fullWidth
				/>

				:

				<div className={classes.storyPreview}>
					<MarkdownDisplay StoryContent={storyContent} />
				</div>
			}

			<br /><br /><br /><br />

			<div className={classes.bottomButtons}>
				<div>
					<Button variant="text" onClick={() => formatting()}>Formatting Help</Button>
					<Button variant="text" onClick={() => navigate('/languages')}>Language Keywords</Button>
				</div>
				<div className={classes.rightFloat}>
					<Button variant="text" style={{ marginRight: 10 }} onClick={() => loadDraft()}>Load Draft</Button>
					<Button variant="text" style={{ marginRight: 10 }} onClick={() => saveDraft()}>Save as Draft</Button>
					{ PostInfo && <Button variant="text" onClick={sharePost} style={{ marginRight: "1rem", color: PostInfo.twitter ? "#5ebd1a" : "#4b8efa" }}>Share</Button> }
					{PostInfo && <Button variant="text" onClick={() => setSocialPromptOpen(true)} style={{ marginRight: "1rem", color: PostInfo.twitter ? "#5ebd1a" : "#4b8efa" }}>{PostInfo.twitter ? 'Change Status ID' : 'Set Status ID'}</Button> }
					{ PostInfo && <Button variant="contained" onClick={() => certainDelete()} style={{ backgroundColor: "red", color: "white", marginRight: "1rem", marginLeft: "0.5rem" }}>Delete</Button> }
					<Button variant="contained" onClick={PostInfo ? () => updateStory() : () => publishStory()}>{PostInfo ? 'Update' : 'Publish'}</Button>
				</div>
			</div>
		</ThemeProvider>
	)
}