import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinearProgress, ThemeProvider, createTheme, Button } from '@mui/material';
import { motion } from 'framer-motion';

import PageTitle from '../components/ui/PageTitle';
import PostButton from '../components/ui/PostButton';
import fetch from '../firebase/fetch';
import fetchLast from '../firebase/fetchLast';
import StringFromDate from '../scripts/StringFromDate';

export default function Posts() {
	const navigate = useNavigate();

	const [recentPosts, setRecentPosts] = useState('Loading...');
	const [earliestPageNum, setEarliestPageNum] = useState('Progress');

	window['bottomReached'] = () => {
		if (earliestPageNum !== 'Progress' && earliestPageNum > 1) {
			setEarliestPageNum('Progress');
			fetch(`/blog/recent/page/${earliestPageNum - 1}`).then(articles => {
				const existingArticles = recentPosts;
				const newArticles = [...existingArticles, ...articles.reverse()];
				setRecentPosts(newArticles);
				setEarliestPageNum(earliestPageNum - 1);
			})
		}
	}

	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Posts', 'page_location': window.location.href, 'page_path': '/posts'});

		fetchLast('blog/recent/page').then(res => {
			setEarliestPageNum(Number(Object.keys(res)[0]));
			setRecentPosts(res[Object.keys(res)].reverse());
		});
	}, []);

	const theme = createTheme({
		palette: {
			mode: 'dark',
		},
	});

	return (
		<motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
			<PageTitle>Posts</PageTitle>
			{ recentPosts === 'Loading...' ? <span>Loading...</span> :
				recentPosts.map(recentPost => {
					if (recentPost !== undefined) {
						const postArray = recentPost.split('|||');
						return <PostButton
							key={postArray[0]}
							Date={StringFromDate(postArray[1])}
							Category={postArray[2]}
							CategoryColour={`#${postArray[3]}`}
							Click={() => navigate(`/post/${postArray[0].toLowerCase().split(' ').join('-')}`)}
						>
							{postArray[0]}
						</PostButton>
					} else {
						return null;
					}
				})
			}
			{ earliestPageNum !== 'Progress' && earliestPageNum > 1 && <Button variant="outlined" onClick={window['bottomReached']}>Load more</Button> }
			<div id="end">{ earliestPageNum === 'Progress' && recentPosts !== 'Loading...' &&
				<ThemeProvider theme={theme}>
					<LinearProgress />
				</ThemeProvider>
			}</div>
		</motion.div>
	)
}