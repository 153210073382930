// import XHRGetRequest from './XHRGetRequest';

const PingRequest = () => {
	return new Promise(resolve => {
		// XHRGetRequest(`https://www.google.com/ping?sitemap=https://sebdoe-api.herokuapp.com/sitemap.xml`).then(() => {
		// 	XHRGetRequest(`https://www.bing.com/webmaster/ping.aspx?siteMap=https://sebdoe-api.herokuapp.com/sitemap.xml`).then(() => {
				resolve();
		// 	});
		// });
	});
}

export default PingRequest;