import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Dialog, DialogContent, DialogContentText, DialogTitle, Button, TextField, ThemeProvider, createTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import { motion } from 'framer-motion';

import PageTitle from '../components/ui/PageTitle';
import CategoryButton from '../components/ui/CategoryButton';
import fetch from '../firebase/fetch';
import write from '../firebase/write';

export default function CategoryManager() {
	let navigate = useNavigate();

	const [categories, setCategories] = useState('Loading...');
	const [open, setOpen] = useState(false);
	const [name, setName] = useState('');

	const categoryKeys = Object.keys(categories);

	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Category Manager', 'page_location': window.location.href, 'page_path': '/categories'});

		fetch('/blog/categories').then(fetchedCategories => setCategories(fetchedCategories));
	}, []);

	const newCategory = () => {
		const nameId = name.toLowerCase().split(' ').join('-');

		write(`/blog/categories/${nameId}/info`, {
			name: name,
			color: 'f2f2f2',
			hasArticles: 0
		}).then(() => {
			navigate(`/category/${nameId}`);
		});
	}

	const theme = createTheme({
		palette: {
			mode: 'dark',
		},
        typography: {
            fontFamily: [
                '"Inter"',
                'sans-serif'
            ].join(','),
        },
	});
	
	return (
		<motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
			<PageTitle>Category Manager</PageTitle>
			
			<ThemeProvider theme={theme}>
				<Dialog open={open} onClose={() => setOpen(false)} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', backdropFilter: 'blur(5px)' }} component={motion.div} initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
					<div style={{ padding: '2rem', backgroundColor: '#141313', textAlign: 'center' }}>
						<DialogTitle>New Category</DialogTitle>
						<DialogContent>
						<DialogContentText>
							Create a new category
						</DialogContentText>
						<TextField
							autoFocus
							margin="dense"
							id="name"
							label="Name"
							fullWidth
							onChange={event => setName(event.target.value)}
							value={name}
							style={{ marginTop: '2rem', marginBottom: '1rem' }}
						/>
						</DialogContent>

						<Button variant="outlined" color="secondary" sx={{ marginRight: '0.5rem' }} onClick={() => setOpen(false)}>Cancel</Button>
						<Button variant="outlined" color="primary" onClick={newCategory}>Create</Button>
					</div>
				</Dialog>

				<div style={{ display: "flex", justifyContent: "right", marginBottom: "1.5rem", marginRight: "1.5rem" }}>
					<IconButton color="primary" aria-label="new category" onClick={() => setOpen(true)}>
						<Add />
					</IconButton>
				</div>
			</ThemeProvider>

			{ categories === 'Loading...' ? <span>Loading...</span> :
				categoryKeys.map(categoryKey => {
					return <CategoryButton Name={categories[categoryKey].info.name} Colour={`#${categories[categoryKey].info.color}`} Click={() => navigate(`/category/${categoryKey}`)} key={categoryKey} />
				})
			}
		</motion.div>
	)
}