import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import SyntaxHighlighter from 'react-syntax-highlighter';

import PageTitle from '../components/ui/PageTitle';

export default function Languages() {
	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Languages', 'page_location': window.location.href, 'page_path': '/languages'});
	}, []);

    const supportedLanguages = SyntaxHighlighter.supportedLanguages

	return (
		<motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
			<PageTitle>Languages</PageTitle>
            <div>
                {supportedLanguages.map(supportedLanguage => {
                    return <p key={supportedLanguage}>{supportedLanguage}</p>
                })}
            </div>
		</motion.div>
	)
}