import React, { useEffect, useState } from 'react';
import { ToggleButtonGroup, ToggleButton, createTheme, ThemeProvider, TextField } from '@mui/material';

import PageTitle from '../components/ui/PageTitle';
import CampaignDisplay from '../components/ui/CampaignDisplay';

export default function Campaign() {
	const [campaignState, setCampaignState] = useState('referral');
	const [campaignSiteName, setCampaignSiteName] = useState(window['campaignSiteName']);
	const [campaignSiteURL, setCampaignSiteURL] = useState(window['campaignSiteURL']);
	const [campaignDescription, setCampaignDescription] = useState(window['campaignDescription']);

	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Campaigns', 'page_location': window.location.href, 'page_path': '/campaigns'});
	}, []);
	
	const darkTheme = createTheme({
		palette: {
			mode: 'dark'
		},
		typography: {
			fontFamily: `"Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
		},
	});

	return (
		<ThemeProvider theme={darkTheme}>
			<PageTitle>Campaigns</PageTitle>

			<ToggleButtonGroup
				size="medium"
				color="primary"
				value={campaignState}
				exclusive
				orientation="horizontal"
				onChange={(event, newAlignment) => {
					if (newAlignment != null) setCampaignState(newAlignment);
					// Prevents value from becoming null
					else setCampaignState(campaignState === 'referral' ? 'referral' : 'affiliate');
				}}
			>
				<ToggleButton value="referral">Referral</ToggleButton>
				<ToggleButton value="affiliate">Affiliate</ToggleButton>
			</ToggleButtonGroup>

			<br /><br /><br />

			<TextField
				id="campaignSiteName"
				label="Site Name"
				variant="outlined"
				value={campaignSiteName}
				fullWidth
				autoFocus
				onChange={newCampaignSiteName => {
					window['campaignSiteName'] = newCampaignSiteName.target.value;
					setCampaignSiteName(newCampaignSiteName.target.value);
				}}
			/>
			
			<br /><br />

			<TextField
				id="campaignSiteName"
				label="Site URL"
				variant="outlined"
				value={campaignSiteURL}
				fullWidth
				onChange={newCampaignSiteURL => {
					window['campaignSiteURL'] = newCampaignSiteURL.target.value;
					setCampaignSiteURL(newCampaignSiteURL.target.value);
				}}
			/>
			
			<br /><br />

			<TextField
				id="campaignSiteName"
				label="Description"
				variant="outlined"
				value={campaignDescription}
				fullWidth
				onChange={newCampaignDescription => {
					window['campaignDescription'] = newCampaignDescription.target.value;
					setCampaignDescription(newCampaignDescription.target.value);
				}}
			/>
			
			<br /><br />

			<CampaignDisplay SiteURL={campaignSiteURL} SiteName={campaignSiteName} Description={campaignDescription} Type={campaignState} />
		</ThemeProvider>
	)
}