import { memo } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';

const DialogElement = ({ open, onClose, onDelete }) => {
	return (
		<Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">
				{"Are you absolutely certain that you want to delete this article?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					All content will be permanently removed from the servers at once.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onDelete}>Delete</Button>
				<Button variant="contained" onClick={onClose} autoFocus>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
}

export default memo(DialogElement);