import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, createTheme, ThemeProvider, IconButton, Dialog, DialogTitle, TextField, MenuItem, Button } from '@mui/material';
import { Add, Delete, Edit, Star, WindowOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import PageTitle from '../components/ui/PageTitle';
import fetch from '../firebase/fetch';
import write from '../firebase/write';
import deleteElement from '../firebase/deleteElement';

export default function Campaigns() {
    let navigate = useNavigate();

    const [campaigns, setCampaigns] = useState('Loading');
    const [mainCampaign, setMainCampaign] = useState('Loading');
    const [dialogType, setDialogType] = useState('new');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [campaignName, setCampaignName] = useState('');
    const [campaignId, setCampaignId] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [campaignURL, setCampaignURL] = useState('');
    const [type, setType] = useState('ref');

    useEffect(() => {
        fetch('/campaigns').then(fetchedCampaigns => {
            setCampaigns(fetchedCampaigns['campaign']);
            setMainCampaign(fetchedCampaigns['main']);
        });
    }, []);

    const editCampaign = campaignID => {
        const campaignInfo = campaigns[campaignID];

        setDialogType('edit');
        setCampaignName(campaignInfo['name']);
        setCampaignId(campaignID);
        setCampaignDescription(campaignInfo['description']);
        setCampaignURL(campaignInfo['url']);
        setType(campaignInfo['type']);
        setDialogOpen(true);
    }

    const setCampaign = () => {
        return new Promise(resolve => {
            write(`/campaigns/campaign/${campaignId}`, {
                description: campaignDescription,
                name: campaignName,
                type: type,
                url: campaignURL
            }).then(() => resolve())
        });
    }

    const changeCampaign = () => {
        setCampaign().then(() => window.location.reload());
    }

    const setCampaignMain = () => {
        return new Promise(resolve => {
            write(`/campaigns/main`, {
                description: campaignDescription,
                id: campaignId,
                name: campaignName,
                type: type
            }).then(() => resolve())
        });
    }

    const newCampaignMain = () => {
        setCampaign().then(() => {
            setCampaignMain().then(() => {
                window.location.reload();
            });
        });
    }

    const changeCampaignMain = (description, id, name, campaignType) => {
        write(`/campaigns/main`, {
            description: description,
            id: id,
            name: name,
            type: campaignType
        }).then(() => window.location.reload())
    }

    const deleteCampaign = (id) => {
        deleteElement(`/campaigns/campaign/${id}`).then(() => window.location.reload())
    }

    const theme = createTheme({
        palette: {
            mode: 'dark',
        },
        typography: {
            fontFamily: [
                '"Inter"',
                'sans-serif'
            ].join(','),
        },
    });

    const types = [
        {
            value: 'ref',
            label: 'Referral'
        },
        {
            value: 'aff',
            label: 'Affiliate'
        }
    ]

    return (
        <motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
            <ThemeProvider theme={theme}>
                <PageTitle>Campaigns</PageTitle>

                <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', backdropFilter: 'blur(5px)' }} component={motion.div} initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
                    <div style={{ padding: '2rem', backgroundColor: '#141313', textAlign: 'center' }}>
                        <DialogTitle sx={{ padding: '0 2rem 2rem 2rem' }}>{ dialogType === 'new' ? 'Create a new campaign' : 'Manage a campaign' }</DialogTitle>
                        <TextField label="Name" variant="outlined" value={campaignName} onChange={(e) => setCampaignName(e.target.value)} fullWidth />
                        <TextField label="ID" variant="outlined" value={campaignId} onChange={(e) => setCampaignId(e.target.value)} sx={{ marginTop: '1rem' }} inputProps={{ maxLength: 15 }} disabled={dialogType === 'edit'} fullWidth />
                        <TextField label="Description" variant="outlined" value={campaignDescription} onChange={(e) => setCampaignDescription(e.target.value)} sx={{ marginTop: '1rem' }} multiline rows={2} inputProps={{ maxLength: 89 }} fullWidth />
                        <TextField label="URL" variant="outlined" value={campaignURL} onChange={(e) => setCampaignURL(e.target.value)} sx={{ marginTop: '1rem' }} fullWidth />
                        <TextField
                            id="outlined-select-campaign"
                            select
                            label="Campaign Type"
                            value={type}
                            onChange={e => setType(e.target.value)}
                            helperText="Please select the type of the campaign"
                            sx={{ marginTop: '1rem' }}
                            fullWidth
                        >
                            {types.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        { dialogType === 'new' && <div style={{ marginTop: '2rem' }}>
                            <Button sx={{ marginRight: '0.5rem' }} variant="outlined" color="secondary" onClick={() => setDialogOpen(false)}>Cancel</Button>
                            <Button sx={{ marginRight: '0.5rem' }} variant="outlined" color="primary" onClick={changeCampaign}>Create</Button>
                            <Button variant="outlined" color="success" onClick={newCampaignMain}>Create and Set Main</Button>
                        </div> }
                        { dialogType === 'edit' && <div style={{ marginTop: '2rem' }}>
                            <Button sx={{ marginRight: '0.5rem' }} variant="outlined" color="secondary" onClick={() => setDialogOpen(false)}>Cancel</Button>
                            <Button variant="outlined" color="primary" onClick={mainCampaign.id === campaignId ? newCampaignMain : changeCampaign}>Update</Button>
                        </div> }
                    </div>
                </Dialog>

                { campaigns === 'Loading' ? <span>Loading...</span> :
                    <div>
                        <div style={{ display: "flex", justifyContent: "right", marginBottom: "1.5rem" }}>
                            <IconButton color="primary" aria-label="new campaign" onClick={() => {
                                setDialogType('new');
                                setDialogOpen(true);
                            }}>
                                <Add />
                            </IconButton>
                        </div>

                        <TableContainer>
                            <Table aria-label="campaigns">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Name</strong></TableCell>
                                        <TableCell><strong>ID</strong></TableCell>
                                        <TableCell><strong>Type</strong></TableCell>
                                        <TableCell><strong>URL</strong></TableCell>
                                        <TableCell align="right" style={{ width: '2rem' }} sx={{ paddingLeft: '0rem', paddingRight: '0rem' }}></TableCell>
                                        <TableCell align="right" style={{ width: '2rem' }} sx={{ paddingLeft: '0rem', paddingRight: '0rem' }}></TableCell>
                                        <TableCell align="right" style={{ width: '2rem' }} sx={{ paddingLeft: '0rem', paddingRight: '0rem' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(campaigns).map(campaignID => {
                                        return <TableRow key={campaignID}>
                                            <TableCell>{campaigns[campaignID].name}</TableCell>
                                            <TableCell>{campaignID}</TableCell>
                                            <TableCell>{campaigns[campaignID].type}</TableCell>
                                            <TableCell>{campaigns[campaignID].url}</TableCell>
                                            <TableCell align="right" sx={{ paddingLeft: '16px', paddingRight: '0rem' }}>
                                                <IconButton sx={{ color: "#ffffff" }} aria-label="edit" onClick={() => editCampaign(campaignID)}>
                                                    <Edit fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="right" sx={{ paddingLeft: '1rem', paddingRight: '0rem' }}>
                                                <IconButton sx={{ color: mainCampaign.id === campaignID ? '#f5d00c' : "#ffffff" }} aria-label="set main" onClick={() => changeCampaignMain(campaigns[campaignID].description, campaignID, campaigns[campaignID].name, campaigns[campaignID].type)}>
                                                    <Star fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="right" sx={{ paddingLeft: '1rem', paddingRight: '16px' }}>
                                                <IconButton sx={{ color: "#f23d3d" }} aria-label="delete" onClick={() => deleteCampaign(campaignID)}>
                                                    <Delete fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
            </ThemeProvider>
        </motion.div>
    );
}