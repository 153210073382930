import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyB0tknfEz5h0SjdptYPazyq1mSc_aeHFKs",
    authDomain: "sebdoe-blog.firebaseapp.com",
    databaseURL: "https://sebdoe-blog-default-rtdb.firebaseio.com",
    projectId: "sebdoe-blog",
    storageBucket: "sebdoe-blog.appspot.com",
    messagingSenderId: "47751545127",
    appId: "1:47751545127:web:7edc2712a2ebf8f56e2902",
    measurementId: "G-HGSNKWLS5N"
};

const firebaseApp = initializeApp(firebaseConfig);
initializeAnalytics(firebaseApp);

export default firebaseApp;