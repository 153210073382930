import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import PageTitle from '../components/ui/PageTitle';
import PostEditor from '../components/ui/PostEditor';
import fetch from '../firebase/fetch';

export default function Post({ isMobile }) {
	const location = useLocation();
	const key = location.pathname.split('/').pop();

	const [postInfo, setPostInfo] = useState('Loading...');

	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Post', 'page_location': window.location.href, 'page_path': window.location.pathname});

		window['storyName'] = '';
		window['category'] = '';
		window['storyText'] = '';

		fetch(`/blog/posts/${key}`).then(res => {
			res['key'] = key;
			setPostInfo(res);
		});
	}, []);

	return (
		<motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
			<PageTitle>Post</PageTitle>
			{ postInfo === 'Loading...' ? <span>Loading...</span> :
				<PostEditor PostInfo={postInfo} isMobile={isMobile} />
			}
		</motion.div>
	)
}