import { getDatabase, ref, query, orderByKey, limitToLast, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";

const getLastChild = path => {
	return new Promise(resolve => {
		const user = getAuth().currentUser;
		if (user && user.uid === "KsunAtlcNiPgDIKBzTkUrfskR993" && user.providerData[0].providerId === 'google.com') {
			const db = getDatabase();
			const lastElementRef = query(ref(db, path), orderByKey(), limitToLast(1));
			onValue(lastElementRef, snapshot => resolve(snapshot.val()));
		} else {
			resolve('You do not have sufficient permissions to access this information');
		}
	});
}

export default getLastChild;