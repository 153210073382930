import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

import PageTitle from '../components/ui/PageTitle';

export default function Home() {
	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Home', 'page_location': window.location.href, 'page_path': '/'});
	}, []);

	return (
		<motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
			<PageTitle>Home</PageTitle>
		</motion.div>
	)
}