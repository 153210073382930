import { getDatabase, ref, remove } from "firebase/database";
import { getAuth } from "firebase/auth";

const deleteElement = path => {
	return new Promise(resolve => {
		const user = getAuth().currentUser;
		if (user && user.uid === "KsunAtlcNiPgDIKBzTkUrfskR993" && user.providerData[0].providerId === 'google.com') {
			const db = getDatabase();
			remove(ref(db, path)).then(() => resolve());
		} else {
			resolve('You do not have sufficient permissions to access this information');
		}
	});
}

export default deleteElement;