import React from 'react';

import classes from './PageTitle.module.css';
import Header from '../../Header';

export default function PageTitle({ children, Colour }) {
	return (
		<div>
			<Header Title={children} />

			<span className={classes.pageTitle} style={{ color: Colour }}>{children}</span>
		</div>
	)
}