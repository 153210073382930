import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getAuth } from '@firebase/auth';

import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Posts from './pages/Posts';
import NewPost from './pages/NewPost';
import Campaign from './pages/Campaign';
import Campaigns from './pages/Campaigns';
import CategoryManager from './pages/CategoryManager';
import Settings from './pages/Settings';
import Post from './pages/Post';
import Help from './pages/Help';
import Languages from './pages/Languages';
import signInUser from './firebase/signInUser';
import signOutUser from './firebase/signOutUser';
import { Button } from '@mui/material';
import Category from './pages/Category';
import { AnimatePresence } from 'framer-motion';

function App() {
    const [user, setUser] = useState(getAuth().currentUser);
    
	const [isMobile, setIsMobile] = useState(window.innerWidth < 870);

	const checkIsMobile = () => {
		const windowWidth = window.innerWidth;
		const desktopMinWidth = 870;

		if (windowWidth >= desktopMinWidth && isMobile) setIsMobile(false);
		else if (windowWidth < desktopMinWidth && !isMobile) setIsMobile(true);
	}
    
    getAuth().onAuthStateChanged(user => {
        setUser(user);
    });

	window.addEventListener('resize', checkIsMobile, true);

    document.addEventListener('keydown', e => {
        if (e.ctrlKey && e.key === 'o') {
            e.preventDefault();
            signOutUser();
        }
    });

    if (window.location.pathname === '/help' && user && user.uid === "KsunAtlcNiPgDIKBzTkUrfskR993" && user.providerData[0].providerId === 'google.com') {
        return <div style={{ padding: '3rem' }}><Help /></div>;
    } else if (user && user.uid === "KsunAtlcNiPgDIKBzTkUrfskR993" && user.providerData[0].providerId === 'google.com') {
        return (
            <Layout isMobile={isMobile}>
                <AnimatePresence exitBeforeEnter>
                    <Routes>
                        <Route path="/" exact element={ <Home /> } />
                        <Route path="/posts" exact element={ <Posts /> } />
                        <Route path="/newpost" exact element={ <NewPost isMobile={isMobile} /> } />
                        <Route path="/campaigns" exact element={ <Campaigns /> } />
                        <Route path="/categories" exact element={ <CategoryManager /> } />
                        <Route path="/settings" exact element={ <Settings /> } />
                        <Route path="/help" exact element={ <Help /> } />
                        <Route path="/languages" exact element={ <Languages /> } />
                        <Route path="/post/*" exact element={ <Post isMobile={isMobile} /> } />
                        <Route path="/category/*" exact element={ <Category /> } />
                        <Route path="/campaign/*" exact element={ <Campaign /> } />
                        <Route path="*" element={ <Home /> } />
                    </Routes>
                </AnimatePresence>
            </Layout>
        );
    } else {
        return (
            <div>
                <Button variant="contained" onClick={() => signInUser()}>Sign In</Button>
            </div>
        );
    }
}

export default App;