import React from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';

export default function NavMenuButton({ children, selected, Click }) {
	const selectedBackground = selected ? {
		backgroundColor: "rgba(255, 255, 255, 0.05);",
		"&:hover": {
			backgroundColor: "rgba(255, 255, 255, 0.05);",
		}
	} : {};

	const theme = createTheme({
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						...selectedBackground,
						width: "100%",
						borderRadius: "0.4rem",
						padding: "0.5rem 1rem",
						border: "none",
						transition: "0.1s ease-in-out",
						cursor: "pointer",
						color: "rgba(255, 255, 255, 0.8)",
						justifyContent: "space-between",
						marginBottom: "0.5rem",
						textTransform: "capitalize",
						fontFamily: "Inter, sans-serif",
						fontWeight: 600,
						fontSize: "1rem",
					}
				}
			}
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Button onClick={Click} fullWidth>{ children }</Button>
		</ThemeProvider>
	)
}