import { Button, ThemeProvider, createTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import PageTitle from '../components/ui/PageTitle';

export default function Settings() {
	let navigate = useNavigate();

	useEffect(() => {
		window.gtag('config', 'G-HGSNKWLS5N', {'page_title': 'Settings', 'page_location': window.location.href, 'page_path': '/settings'});
	}, []);

	const theme = createTheme({
		palette: {
			mode: 'dark',
		},
        typography: {
            fontFamily: [
                '"Inter"',
                'sans-serif'
            ].join(','),
        },
	});

	return (
		<motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.8 }}>
			<ThemeProvider theme={theme}>
				<PageTitle>Settings</PageTitle>
				<Button onClick={() => navigate('/post/about')} variant="outlined">Edit About</Button>
			</ThemeProvider>
		</motion.div>
	)
}